<template>
    <v-container>
        <v-card>
            <v-card-title>
                <s-toolbar dark color="primary" save label="Configuracion de camaras" @save="saveChamber()" ></s-toolbar>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-row cols="3" lg="3" md="6" sm="12">
                            <v-col>
                                <s-text label="Descripccion" v-model="Chamber.CdtDescription"></s-text>
                            </v-col>
                            <v-col cols="3" lg="3" md="6" sm="12">
                                <s-text label="Capacidad de Pallets" v-model="Chamber.CdtCapacity"></s-text>
                            </v-col>
                            <v-col cols="3" lg="3" md="6" sm="12">
                                <s-text label="Capacidad Tonelas" v-model="Chamber.CdtCapacityTon"></s-text>
                            </v-col>
                            <v-col cols="3" lg="3" md="6" sm="12">
                                <s-select-definition 
                                    :def=1450 label="Almacenes de congelado" 
                                    v-model="Chamber.CdtWarehouses"
                                    @input="listChamber($event)"
                                >
                                </s-select-definition>  
                            </v-col>
                            <v-col cols="3" lg="3" md="6" sm="12">
                                <s-text label="Lados" v-model="Chamber.CdtNumberSides"></s-text>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-data-table 
                                    dense
                                    :headers="HeadersChamber"
                                    :items="ItemsChamber"
                                    :items-per-page="-1"
                                    disable-sort
                                    :single-select="singleSelect"
                                    
                                    
                                    @click:row="rowClickPallet"
                                    item-key="CdtID"                                    
                                    class="elevation-1"
                                    >

                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions v-if="singleSelect">
                <v-row>
                    <v-col cols="12">
                        <v-row>
                            <v-col>
                                <s-select-definition 
                                    label="Lado de camara" 
                                    :def="1451"                                    
                                    v-model="ChamberDetail.SideID">

                                </s-select-definition>
                            </v-col>
                            <v-col>
                                <s-text label="Carriles" number v-model="ChamberDetail.NumberLanes"></s-text>
                            </v-col>
                            <v-col>
                                <s-text label="Inicio" number v-model="ChamberDetail.NumberStart"></s-text>
                            </v-col>
                            <v-col>
                                <s-text label="Niveles por carril" number v-model="ChamberDetail.NumberLevels"></s-text>
                            </v-col>
                            <v-col>
                                <s-text label="Posiciones por nivel" number v-model="ChamberDetail.PositionsLevel"></s-text>
                            </v-col>
                            <v-col>
                                <v-btn 
                                small
                                fab
                                elevation="3"
                                color="info" @click="saveChamberDetail()">
                                <v-icon style="font-size: 16px !important;"
                                    >fa-thin fa-save</v-icon>
                            </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-data-table 
                                    dense
                                    :items-per-page="-1"
                                    disable-sort 
                                    :headers="HeadersChamberDetail"
                                    :items="SelectChamberDetail"
                                    @click:row="rowClickDetail"
                                    class="elevation-1">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-container>

</template>

<script>
    import _sChamber from '@/services/FrozenProduction/FrzChamberDispatchTunnelsChamber.js';

    export default {
 
        data(){
            return {
                Chamber: { CdtID : 0,
                            CdtWarehouses : 1},
                HeadersChamber: [
                    { text: "ID", value: "CdtID", width: 20 },                    
                    { text: "Descripcion", value: "CdtDescription", width: 20 },
                    { text: "Capacidad", value: "CdtCapacity", width: 20 },
                    { text: "Capacidad Ton.", value: "CdtCapacityTon", width: 20 },
                ],
                HeadersChamberDetail: [
                    { text: "ID", value: "CddID", width: 20 },                    
                    { text: "Lado de Camara", value: "SideDescription", width: 20 },
                    { text: "Carriles", value: "NumberLanes", width: 20 },
                    { text: "Niveles por carril", value: "NumberLevels", width: 20 },
                    { text: "Posiciones Por Nivel", value: "PositionsLevel", width: 20 },
                ],
                ItemsChamber: [],
                SelectChamber: {}, 
                ChamberDetail: {},
                singleSelect: false,
                SelectChamberDetail: [],
                CdtWarehouses : 0,

            }
        },
        methods:{
            saveChamber(){

                if(this.Chamber.CdtDescription == "")
                {
                    this.$fun.alert("Agregue una descripcion", "warning");
                    return;
                }
                
                if(this.Chamber.CdtNumberSides == "")
                {
                    this.$fun.alert("Agregue una descripcion", "warning");
                    return;
                }
                this.CdtWarehouses = this.Chamber.CdtWarehouses
                // return
                this.Chamber.UsrID = this.$fun.getUserID();
                _sChamber.save(this.Chamber, this.$fun.getUserID()).then((r) => {
                    if(r.status == 200){
                        this.$fun.alert("Se registro correctamente", "success");
                        this.Chamber = {}
                        this.singleSelect = false;	
                        this.initialize;
                    }
                });
            },
            initialize(){

                _sChamber.list({CdtWarehouses: this.Chamber.CdtWarehouses}, this.$fun.getUserID()).then((r) => {
                // _sChamber.list({CdtWarehouses : 1}, this.$fun.getUserID()).then((r) => {

                    if(r.status == 200){
                        this.ItemsChamber = r.data;
                        this.Chamber.CdtID = 0;
                    }
                });
            },
            listChamber(CdtWarehouses){
                console.log('Almacen',CdtWarehouses);
                _sChamber.list({CdtWarehouses: CdtWarehouses}, this.$fun.getUserID()).then((r) => {
                    if(r.status == 200){
                        this.ItemsChamber = r.data;
                        this.Chamber.CdtID = 0;
                    }
                });
            },
            saveChamberDetail(){
                
                if(this.Chamber.CdtID == 0)
                {
                    this.$fun.alert("Seleccione una camara", "warning");
                    return;
                }
                
                if(this.ChamberDetail.NumberLanes == "")
                {
                    this.$fun.alert("Agregue numero de carriles", "warning");
                    return;
                }

                if(this.ChamberDetail.NumberLevels == "")
                {
                    this.$fun.alert("Agregue numero de niveles", "warning");
                    return;
                }

                if(this.ChamberDetail.PositionsLevel == "")
                {
                    this.$fun.alert("agregue posiciones por nivel", "warning");
                    return;
                }
                let tunnelsDetails = [];
                this.ChamberDetail.CdtID = this.Chamber.CdtID;
                tunnelsDetails.push(this.ChamberDetail);
                this.Chamber.tunnelsDetails = tunnelsDetails;
                
                this.Chamber.UsrUpdate= this.$fun.getUserID();
                console.log(this.Chamber);
                _sChamber.save(this.Chamber, this.$fun.getUserID()).then((r) => {
                    if(r.status == 200){
                        this.$fun.alert("Se registro correctamente", "success");
                        this.Chamber = {}
                        this.ChamberDetail = []
                        this.singleSelect = false;	
                        this.initialize();
                        // this.initialize();
                    }
                });
            },            
            rowClickPallet: function(item, row) 
            {	console.log('item',item);
                this.singleSelect = true;	
                let chamber = 	[item];	
                this.SelectChamber = chamber[0];                
                this.Chamber = this.SelectChamber;
                this.SelectChamberDetail = []
                this.SelectChamberDetail = this.Chamber.tunnelsDetails;
                
			},
            rowClickDetail: function(item, row)
            {
                console.log('detalle');
                let detail = [item];
                this.ChamberDetail = detail[0];
            }
        },
        created(){
            this.initialize();
            
        },
        mounted(){

        }
    }
</script>